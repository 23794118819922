
import { defineComponent } from "vue";

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as Yup from "yup";
import SearchTable from "@/components/wizard/SearchTable.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Datatable,
    SearchTable
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      constInput: 3,
      empDistricts: [],
      tableData: [],
      tableHeader: [
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "TI",
          key: "ti",
          sortable: true,
        },
        {
          name: "Course",
          key: "course",
          sortable: true,
        },
        {
          name: "BN",
          key: "bn",
          sortable: true,
        },
        {
          name: "Enroll #(A)",
          key: "enroll",
          sortable: true,
        },
        {
          name: "Enroll Fee %",
          key: "enroll_fee",
          sortable: true,
        },
        {
          name: "Course Fee (B)",
          key: "course_fee",
          sortable: true,
        },
        {
          name: "Total Bill (C=A*B)",
          key: "total_bill",
          sortable: true,
        },
        {
          name: "Claim 1(D)",
          key: "claim_1",
          sortable: true,
        },
        {
          name: "Cer #(E)",
          key: "cer",
          sortable: true,
        },
        {
          name: "	Net Bill (F=B*E)",
          key: "net_bill",
          sortable: true,
        },
        {
          name: "Cer Fee % (CF)	",
          key: "cer_fee",
          sortable: true,
        },
        {
          name: "Claim 2 (G= F % (CF))	",
          key: "claim_2",
          sortable: true,
        },
        {
          name: "Job # (H)",
          key: "job",
          sortable: true,
        },
        {
          name: "Claim 3 (I = F- (D+G))	",
          key: "claim_3",
          sortable: true,
        },
        {
          name: "Bill Date",
          key: "bill_date",
          sortable: true,
        },
        {
          name: "Bill No",
          key: "bill_no",
          sortable: true,
        },
        {
          name: "Start Date",
          key: "start_date",
          sortable: true,
        },
        {
          name: "End Date",
          key: "end_date",
          sortable: true,
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      web_url: Yup.string().required().label("Web Url"),
      postcode: Yup.string().required().max(4).label("Postcode"),
      registration_number: Yup.string().required().label("Registration Number"),
      registration_authority: Yup.string()
        .required()
        .label("Registration Authority"),
      telephone: Yup.number().required().min(11).label("Telephone"),
      entity_name: Yup.string().required().label("Name"),
      entity_short_name: Yup.string().required().label("Short Name"),

      employeeEmail: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      //presentDistrict: Yup.string().required().label("Present District"),
      name: Yup.string().required().label("Name"),
      designation: Yup.string().required().label("Designation"),
      gender: Yup.string().required().label("Gender"),
      mobile: Yup.number().required().min(11).label("Mobile"),
      dob: Yup.string().required().label("Date of Birth"),

      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .label("Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password didn't match!")
        .label("Confirm Password"),
    });
    return {
      AssociationSchema,
    };
  },
});
